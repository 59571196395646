#hamburguer.dropdown-toggle:after {
  content: none;
}

.nav-item.dropdown .dropdown-item:active {
  background-color: #dc3545 !important;
}

.dropdown-item.active {
  background-color: #dc3545 !important;
}

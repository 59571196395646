.landing-carousel {
  height: 350px;
  width: 100%;
  object-fit: cover;
}

.featurette-heading {
  letter-spacing: -0.05rem;
}

@media (min-width: 40em) {
  .carousel-caption p {
    /* margin-bottom: 2.25rem; */
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.brand-header {
  font-size: 2em !important;
}

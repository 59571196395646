@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap");

.no-style-link {
  color: inherit;
  text-decoration: inherit;
}

.no-style-link:hover {
  color: inherit;
}

.left-fixed-div {
  width: 150px;
}

.circle-cropper {
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #adb5bd;
  background-color: white;
}

.w-200 {
  width: 200px;
}

.h-201 {
  height: 201px;
}

.div-vp {
  height: 100vh;
  background: #212529;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.pointer {
  cursor: pointer;
}

.no-resize {
  resize: none;
}

.text-box {
  display: block;
  white-space: pre-line;
}

.comments-badge {
  position: absolute !important;
  top: 8px !important;
  right: 16px !important;
}

.z-index-1 {
  z-index: 1;
}

.resize-none {
  resize: none;
}

.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.9);
}

.gdn {
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  color: #dc3545;
}

.same-height-imgs .card-img-top {
  width: 100%;
  height: 33vh;
  min-height: 200px;
  object-fit: cover;
}

.blue-filter {
  filter: blur(2px) sepia(300%) hue-rotate(185deg) saturate(450%);
}

.size-x {
  font-size: 36px !important;
}

.img-cover {
  object-fit: cover;
  width: 100%;
  height: 350px;
}
